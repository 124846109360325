.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: "10px";
  }

  .button:hover, .button:visited, .button:link, .button:active
  {
      text-decoration: none;
      color: white;
      background-color: #359e39;

  }