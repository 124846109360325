nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #30419A;
  z-index: 7;
  /* border-bottom: 2px solid #283353; */
}

.list {
  list-style-type: none;
  height: 100%;
  display: flex;
  background-color: #283353;

  /* justify-content: center;
  align-items: center; */
  position: relative;
}
.items {
  margin-right: 20px;
  font-size: 15px;
  /* text-transform: uppercase; */
  color: white;

  padding: 14px 16px;
}
.account {
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  padding: 5px;
}

.account:hover {
  background-color: #2f55d4;
  border: none;
  font-weight: bolder;
}
.logo {
  margin-right: 20px;
  font-size: 15px;
  /* text-transform: uppercase; */
  color: #2f55d4;

  padding: 14px 16px;
}

.logo:hover {
  /* margin-right: 20px;
  font-size: 20px;  
  background-color: #66e0ff;


  color: #f1f1f1;

  padding: 14px 16px; */

  background-color: #66e0ff;
}

.items:hover {
  background-color: #212a45;
  border-bottom: 5px solid #2f55d4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
}

.btnNav {
  display: none;
  background-color: #283353;
  border: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: white;
  font-size: 18px;
}

@media screen and (max-width: 850px) {
  .list {
    flex-direction: column;
    height: auto;
    padding: 40px !important;
    padding-bottom: 40px !important;
  }
  .items:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 50px;
  }
  .items {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    margin: 20px;
  }
  .btnNav {
    display: block;
  }
}
