//
// general.scss
//

body {
  font-family: $font-family-secondary;
  overflow-x: hidden !important;
  font-size: $font-size-base;
  color: $black;
}

::selection {
  background: rgba($primary, 0.9);
  color: $white;
}
a {
  // text-decoration: none !important;
}
p {
  line-height: 1.6;
}
