.contact-form {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form form {
  display: flex;
  width: 70%;
  height: 60vh;
  background-color: #fff;
  box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.01);
}

.contact-left {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-right {
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
}
.contact-right button {
  width: 100%;
  height: 50px;
  background-color: #1ed98b;
  font-weight: bold;
  outline: none;
  border: none;
  margin: 0px;
  color: #1b1b1b;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.c-l-heading {
  font-family: calibri;
  color: #ffffff;
  font-size: 3rem;
  letter-spacing: 2px;
  font-weight: 400;
}
.f-name,
.f-email {
  display: flex;
  flex-direction: column;
  font-family: calibri;
}
.f-name font,
.f-email font {
  color: #bfbfbf;
  font-size: 22px;
}
.f-name input,
.f-email input {
  height: 30px;
  width: 250px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #929292;
  color: #ffffff;
  margin: 10px 0px;
}
.f-email input::placeholder,
.f-name input::placeholder {
  opacity: 0.5;
  letter-spacing: 1px;
}
.f-email input:focus,
.f-name input:focus {
  border-bottom: 1px solid #17d1ac;
  transition: all ease 0.5s;
}
.message font {
  font-size: 18px;
  color: #4e4e4e;
  font-family: calibri;
}
.message {
  margin: 20px;
}
.contact-right textarea {
  width: 100% !important;
  height: 320px !important;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  color: #ebebeb;
  font-size: 16px;
}
.contact-right textarea::placeholder {
  color: #ebebeb;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: calibri;
}
.contact-right button:active {
  transform: scale(1.04);
  transition: all ease 0.1s;
}
