//
// features.scss
//
.features {
  .icon {
    background: rgba($primary, 0.1);
  }
  .image {
    &:before {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 5px;
      width: 64px;
      height: 64px;
      border-radius: 6px;
      transform: rotate(33.75deg);
      background: linear-gradient(45deg, transparent, rgba($primary, 0.1));
    }
  }
  &.feature-clean {
    .icons {
      i {
        height: 65px;
        width: 65px;
        line-height: 65px;
        background-color: rgba($primary, 0.1);
      }
    }
    .title {
      &:hover {
        color: $primary !important;
      }
    }
  }
  .ride-image {
    transform: rotate(-45deg);
    img {
      border-radius: 100% 100% 100% 0;
    }
  }
  &:hover {
    .image {
      &:before {
        background: rgba($primary, 0.1);
        animation: spinner-border 5s linear infinite !important;
      }
    }
  }
  &.fea-primary {
    transition: all 0.5s ease;
    .content {
      .title {
        transition: all 0.5s ease;
        &:hover {
          color: $primary !important;
        }
      }
    }
    .big-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      opacity: 0.015;
      font-size: 180px;
    }
    &:hover {
      box-shadow: $shadow-lg;
      background-color: $primary !important;
      color: $white !important;
      .icon2,
      .para {
        color: rgba($white, 0.5) !important;
      }
      .content,
      .icon2 {
        z-index: 2;
      }
      .big-icon {
        opacity: 0.05;
      }
    }
  }
}
.features,
.key-feature {
  .title {
    font-size: 18px !important;
  }
  .icon {
    height: 45px;
    width: 45px;
    line-height: 45px;
  }
}

.key-feature {
  transition: all 0.3s ease;
  .icon {
    background: linear-gradient(45deg, transparent, rgba($primary, 0.15));
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: $shadow-md !important;
  }
}

// Work process
.work-process {
  transition: all 0.5s ease;
  .step,
  .step-icon {
    opacity: 0.06;
  }
  a {
    font-size: 15px;
  }
  &.process-arrow {
    &:after {
      content: "";
      position: absolute;
      width: 60%;
      height: 40px;
      top: 80px;
      left: 80%;
      background: url("../../images/process.png") center center no-repeat;
      z-index: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }
    &:hover {
      transform: none !important;
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
  &.d-none-arrow {
    &:hover {
      transform: none !important;
    }
  }
}

//course.html
.course-feature {
  transition: all 0.5s ease;
  .full-img {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto;
    z-index: -2;
    transition: all 0.5s ease;
    font-size: 250px;
  }
  &:hover {
    transform: translateY(-10px);
    .full-img {
      opacity: 0.05;
    }
  }
}

//index-crypto.html and index-course.html
.features-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.features-absolute {
  margin: -200px 0 0px;

  @media (max-width: 768px) {
    margin: -140px 0 0;
  }

  &.blog-search {
    margin: -120px 0 0px;

    @media (max-width: 768px) {
      margin: -90px 0 0px;
    }
  }
}

//index-job.html
.categories {
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-10px);
    box-shadow: $shadow;
  }
  a {
    &:hover {
      color: $primary !important;
    }
  }
}

//Classic App
.app-feature-shape-right,
.app-feature-shape-left {
  &:after {
    bottom: 0rem;
    width: 30rem;
    height: 40rem;
    border-radius: 10rem;
  }
}

.app-feature-shape-right {
  &:after {
    left: 10rem;
    transform: rotate(150deg);

    @media (max-width: 768px) {
      left: 0;
    }
  }
}

.app-feature-shape-left {
  &:after {
    right: 10rem;
    transform: rotate(210deg);

    @media (max-width: 768px) {
      right: 0;
    }
  }
}

.app-subscribe {
  position: absolute;
  top: -200px;

  @media (max-width: 767px) {
    position: relative;
    top: 0;
  }
}

//Classic Saas
.saas-feature-shape-left,
.saas-feature-shape-right {
  &:after {
    bottom: -3rem;
    width: 35rem;
    height: 35rem;
    border-radius: 15rem;
    transform: rotate(145deg);

    @media (max-width: 767px) {
      bottom: -1rem;
      width: 25rem;
      height: 25rem;
      border-radius: 10rem;
    }
  }
}
.saas-feature-shape-left {
  &:after {
    left: -10rem;
  }
}

.saas-feature-shape-right {
  &:after {
    right: -10rem;
  }
}

.app-feature-shape-right,
.app-feature-shape-left,
.saas-feature-shape-left,
.saas-feature-shape-right,
.social-feature-left,
.social-feature-right {
  &:after {
    background: rgba($primary, 0.1);
    box-shadow: 0 0 40px rgba($primary, 0.1);
  }
}

.app-feature-shape-right,
.app-feature-shape-left,
.saas-feature-shape-left,
.saas-feature-shape-right,
.social-feature-left,
.social-feature-right {
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
  }
}

//Social Media Marketing
.social-feature-left,
.social-feature-right {
  &:after {
    width: 450px;
    height: 520px;
    border-radius: 30px;
    bottom: -75px;
    @media (max-width: 768px) {
      width: 300px;
      height: 400px;
      bottom: -65px;
    }

    @media (max-width: 575px) {
      bottom: -35px;
    }
  }
}
.social-feature-left {
  &:after {
    right: -200px;
    @media (max-width: 575px) {
      right: 60px;
    }
  }
}
.social-feature-right {
  &:after {
    left: -200px;
    @media (max-width: 575px) {
      left: 60px;
    }
  }
}

.explore-feature {
  transition: all 0.5s ease;
  .icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    transition: all 0.5s ease;
  }
  &:hover {
    box-shadow: $shadow-lg !important;
    .icon,
    .title {
      color: $primary !important;
    }
  }
}

//Construction
.construction-img {
  position: absolute;
  bottom: 0;
  right: 20px;
}
